import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Grocery Delivery Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/grocery-delivery-web-app/"
    metaDescription="Designing a grocery website? Our grocery website design template is here to supercharge your productivity. Sign up to Uizard and bring your idea to life."
    description="
    h2:A grocery website design template to kickstart your next project
    <br/>
    Looking to design your very own grocery delivery website? Our brand-new grocery template for <a:https://uizard.io/templates/website-templates/>website design</a> is here to help you bring your vision to life in seconds. Simply sign up to Uizard Pro, select the grocery website template and you are good to go.
    <br/>
    h3:Rapid design with Uizard's drag-and-drop editor
    <br/>
    Want to put your own spin and things? Updating and editing the template is easy with Uizard's rapid drag-and-drop editor. Add new screens in a flash, swap out imagery and text in seconds, and update user journeys in no time at all. Looking to fast-track the success of your design project even more? Uizard's <a:https://uizard.io/ai-design/>AI design</a> features support you to transform screenshots of other apps into editable designs and import your hand-drawn wireframes.
    <br/>
    h3:Collaboration made easy with Uizard's smart sharing features 
    <br/>
    Work solo or share your project with your team members. Uizard is designed for real-time collaboration, meaning you can work together to bring your ideas to life like never before. Looking to get crucial feedback on your project? Share an interactive preview of your design with internal or external stakeholders to get insight into what's working and what's not.
    "
    pages={[
      "A clean and simple log-in screen",
      "Grocery store shopping pages with product lists, categories, and a favorites screen", 
      "Product display pages with add-to-basket CTA",
      "Basket and order details screens to complete your user journey",
    ]}
    projectCode="BoG0rRjw3dfeAjn8nOE1"
    img1={data.image1.childImageSharp}
    img1alt="Grocery Deliver Web App cover page"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Grocery Deliver Web App shopping page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Grocery Deliver Web App product display page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Grocery Deliver Web App: image details page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Grocery Deliver Web App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/grocery-delivery-web-app/grocery-store-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/grocery-delivery-web-app/grocery-store-web-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/grocery-delivery-web-app/grocery-store-web-product-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/grocery-delivery-web-app/grocery-store-web-checkout.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/grocery-delivery-web-app/grocery-store-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
